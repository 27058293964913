
.photo{
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.photo h1{
    margin-left: 60px;
    margin-bottom: 30px;
    padding-top: 50px;

    font-size: 50px;
    font-family: monospace;
    color: rgb(121, 14, 167);
}

.page_photo{
    padding-top: 30px;
}

.model_photo{
    height: 230px;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 3%;
}

.photo_content{
    display: inline-flex;
    flex-direction: row;
    margin-left: 20px;
    padding-bottom: 10px;
}

@media screen and (max-width: 550px){
    
    .photo h1{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding-top: 20px;
    
        font-size: 30px;
    }
    
    .model_photo{
        width: 300px;
        height: 100%;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 5%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .photo_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .link{
        font-family: monospace;
        color: rgb(121, 14, 167);
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        padding-bottom: 30px;
        margin-right: auto;
        padding-top: 0%;
    }
}