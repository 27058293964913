@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(90deg, rgba(0, 170, 119, 1) 15%, rgba(21, 57, 220, 1) 50%, rgba(170, 30, 156, 1) 86%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg)
}

a {
  color: unset;
  text-decoration: none;
}

.app_bg

{
  background:linear-gradient(90deg, rgba(0, 186, 223, 1) 0%, rgba(147, 0, 147, 1) 61%);
}

.main{
  background-color: white;
}

.app_text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 0.5rem 2rem;
    padding-top: 30px;
  }
  
  .navbar-links{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .navbar-links_logo{
    margin-right: 2rem;
  }
  
  .navbar-links_logo img{
    width: 50px;
    height: 50px;
  }
  
  .navbar-links_logo p{
    align-items: center;
    color: rgba( 0, 0, 0, 0.7);
    font-weight: bold;
    font-size: 40px;
    font-family: 'Courier New', Courier, monospace;
    padding: auto;
  }
  
  .navbar-links_container{
    display: flex;
    flex-direction: row;
  }
  
  .navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .navbar-links_container p,
  .navbar-menu_container p,
  .navbar-sign p{
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
  }
  
  .navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
    z-index: 1;
  }
  
  .navbar-menu svg{
    cursor: pointer;
  }
  
  .navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    text-align: end;
    background: rgb(9, 95, 121);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 25px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  }
  
  .navbar-menu_container p{
    margin: 1rem 0;
  }
  
  @media screen and (max-width: 1050px) {
    .navbar-links_container{
      display: none;
    }
  
    .navbar-menu {
      display: flex;
    }
  }
  
  
  @media screen and (max-width: 700px) {
      .navbar {
          padding: 2rem 4rem;
      }
  }
  
  @media screen and (max-width: 550px) {
      .navbar {
          padding: 2rem;
      }
  
      .navbar-menu_container {
          top: 20px;
      }
  
      .navbar-menu_container-links-sign {
          display: block;
      }
  }
  
.skill_content {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.skill_image {
    margin-left: 35px;
    height: 70px;
    width: 70px;
    margin-bottom: 30px;
}
.softwares_bg {
    background-color: black;
    padding-bottom: 50px;
    text-align: center;
}

.softwares_bg li{
    display: inline-flex;
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 70px;
    color: snow;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .softwares_bg li{
        font-size: 15px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }   
}
.header-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name {
    text-align: center;
    font-size: 100px;
    color: black;
  }

.title {
    text-align: center;
    padding-top: 2%;
    color: snow;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
}

.moto {
    font-size: 30px;
    text-align: center;
    padding: 2%;
    color: black;
}

@media screen and (max-width: 550px) {
    .name {
        font-size: 40px;
        margin-bottom: 10px;
      }

      .title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .moto {
        font-size: 20px;
        text-align: center;
    }
}
.footer {
    background-image: url(/static/media/w3.7ee967c4.png);
    background-size: 100%;

    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 20px;
}

.footer h1{
    color: lightcyan;
    margin-bottom: 30px;
    font-size: 50px;
    font-family: 'Courier New', Courier, monospace;
}

.footer-content{
    display: inline-flex;
    margin-right: 70px;
}

.footer p{
    color: snow;
    font-size: 25px;
    text-align: end;
    font-family: 'Courier New', Courier, monospace;
}

.footer h3{
    color: white;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
}

.footer i{
    color: white;
    font-size: 25px;
    padding-bottom: 10px;
}

.footer ul{
    list-style-type: none;
}

.ad a{
    color: white;
}

.ad i{
    font-size: 15px;
    color: red;
}

.footer_skill{
    display: inline-flex;
    flex-direction: column;
}

@media screen and (max-width: 550px){
    .footer {
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .footer h1{
        color: lightcyan;
        margin-bottom: 20px;
        font-size: 30px;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .footer-content{
        display: block;
        margin-left: auto;
    }
    
    .footer p{
        color: snow;
        font-size: 20px;
        text-align: left;
        font-family: 'Courier New', Courier, monospace;
        margin-bottom: 20px;
    }
    
    .footer h3{
        color: white;
        text-align: center;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 15px;
    }
    
    .footer i{
        color: white;
        font-size: 20px;
        padding-bottom: 10px;
    }
    
    .footer ul{
        list-style-type: none;
    }

    .ad{
        margin-top: 10px;
    }
    
    .ad a{
        color: white;
    }
    
    .ad i{
        font-size: 15px;
        color: red;
    }

    .footer_skill{
        display: none;
    }
}
.about_content_box {
    width: 800px;
    margin-right: 50px;
    margin-left: auto;
}

.about h1 {
    color: white;
    margin-left: 50px;
    font-size: 50px;
    font-family: 'Courier New', Courier, monospace;
}

.about_content_box p {
    color: white;
    text-align: justify;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 50px;
}

.about{
    background-image: url(/static/media/w2.2152470b.png);
    background-size: 100%;
    padding-bottom: 50px;
    padding-top: 50px;
}

@media screen and (max-width: 550px){
    .about_content_box {
        width: 300px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .about h1 {
        margin-left: 20px;
        font-size: 40px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .about_content_box p {
        font-size: 20px;
        font-family: 'Courier New', Courier, monospace;
        padding-bottom: 20px;
    }
    
    .about{
        padding-bottom: 20px;
        padding-top: 20px;
    }   
}
.link{
    font-family: monospace;
    color: rgb(121, 14, 167);
    font-size: 30px;
    font-weight: 800;
    text-align: right;
    padding: 20px;
    margin-right: 100px;
}

.page_anime{
    padding-top: 30px;
}

.page_heading{
    font-family: monospace;
    color: snow;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    padding: 20px;
}

.animation h1 {
    margin-left: 50px;
    margin-bottom: 30px;
    padding-top: 50px;

    font-size: 50px;
    font-family: monospace;
    color: rgb(121, 14, 167);
}

.d_model_photo {
    height: 230px;
    margin-left: 30px;
    margin-bottom: 20px;
    border-radius: 5%;
}

.animation_content{
    display: inline-flex;
    flex-direction: row;
}

.animation_video_content{
    display: inline-flex;
    flex-direction: row;
}

.animation_video_content video {
    height: 230px;
    margin-left: 30px;
    margin-bottom: 20px;
    border-radius: 5%;
}

.animation {
    padding-left: 30px;
    padding-right: 10px;
    background-color: black;
    padding-bottom: 10px;
}

@media screen and (max-width: 550px){
    .animation h1 {
        margin-bottom: 20px;
        padding-top: 20px;
    
        font-size: 30px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .d_model_photo {
        width: 300px;
        height: 100%;
        margin-bottom: 20px;
        border-radius: 5%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .animation_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .animation_video_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .animation_video_content video {
        width: 300px;
        height: 100%;
        margin-bottom: 20px;
        border-radius: 5%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .animation {
        padding-left: auto;
    }

    .link{
        font-family: monospace;
        color: rgb(121, 14, 167);
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        padding-bottom: 30px;
        margin-right: auto;
        padding-top: 0%;
    }
}

.photo{
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.photo h1{
    margin-left: 60px;
    margin-bottom: 30px;
    padding-top: 50px;

    font-size: 50px;
    font-family: monospace;
    color: rgb(121, 14, 167);
}

.page_photo{
    padding-top: 30px;
}

.model_photo{
    height: 230px;
    margin-left: 20px;
    margin-bottom: 20px;
    border-radius: 3%;
}

.photo_content{
    display: inline-flex;
    flex-direction: row;
    margin-left: 20px;
    padding-bottom: 10px;
}

@media screen and (max-width: 550px){
    
    .photo h1{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding-top: 20px;
    
        font-size: 30px;
    }
    
    .model_photo{
        width: 300px;
        height: 100%;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 5%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .photo_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .link{
        font-family: monospace;
        color: rgb(121, 14, 167);
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        padding-bottom: 30px;
        margin-right: auto;
        padding-top: 0%;
    }
}
.page_music{
    padding-top: 30px;
}


.music{
    background-color: black;
}

.music h1{
    padding-top: 50px;
    margin-left: 50px;
    margin-bottom: 30px;

    font-size: 50px;
    font-family: monospace;
    color: snow;
}

.music_container {
  position: relative;
  width: 210px;
  display: inline-flex;
  margin-left: 50px;
  margin-bottom: 40px;
}

.music_container img {
    height: 260px;
    width: 210px;
    border-radius: 2%;
}

.music_container audio{
    position: absolute;
    width: 200px;
    height: 30px;
    top: 90%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
}


.music_container audio::-webkit-media-controls-seek-back-button,
.music_container audio::-webkit-media-controls-seek-forward-button,
.music_container audio::-webkit-media-controls-fullscreen-button,
.music_container audio::-webkit-media-controls-rewind-button,
.music_container audio::-webkit-media-controls-return-to-realtime-button,
.music_container audio::-webkit-media-controls-toggle-closed-captions-button,
.music_container audio::-webkit-media-controls-mute-button,
.music_container audio::-webkit-media-controls-volume-slider-container,
.music_container audio::-webkit-media-controls-volume-slider{
    display: none;
}

.music_container audio::-webkit-media-controls-timeline{
    width: 50px;
}

.music_container audio::-webkit-media-controls-current-time-display,
.music_container audio::-webkit-media-controls-time-remaining-display{
    font-size: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.music_container audio::-webkit-media-controls-panel{
    background-color: white;
}

.music_container p{
    position: absolute;
    width: 200px;
    background-color: rgb(102, 102, 102);
    color: white;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media screen and (max-width: 550px){
    .music h1{
        padding-top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 20px;
    
        font-size: 30px;
    }

    .music_container {
        position: relative;
        width: 210px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    .music_container audio{
        top: 80%;
    }
}
