.about_content_box {
    width: 800px;
    margin-right: 50px;
    margin-left: auto;
}

.about h1 {
    color: white;
    margin-left: 50px;
    font-size: 50px;
    font-family: 'Courier New', Courier, monospace;
}

.about_content_box p {
    color: white;
    text-align: justify;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 50px;
}

.about{
    background-image: url("../../assets/bg_img/w2.png");
    background-size: 100%;
    padding-bottom: 50px;
    padding-top: 50px;
}

@media screen and (max-width: 550px){
    .about_content_box {
        width: 300px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .about h1 {
        margin-left: 20px;
        font-size: 40px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .about_content_box p {
        font-size: 20px;
        font-family: 'Courier New', Courier, monospace;
        padding-bottom: 20px;
    }
    
    .about{
        padding-bottom: 20px;
        padding-top: 20px;
    }   
}