.link{
    font-family: monospace;
    color: rgb(121, 14, 167);
    font-size: 30px;
    font-weight: 800;
    text-align: right;
    padding: 20px;
    margin-right: 100px;
}

.page_anime{
    padding-top: 30px;
}

.page_heading{
    font-family: monospace;
    color: snow;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    padding: 20px;
}

.animation h1 {
    margin-left: 50px;
    margin-bottom: 30px;
    padding-top: 50px;

    font-size: 50px;
    font-family: monospace;
    color: rgb(121, 14, 167);
}

.d_model_photo {
    height: 230px;
    margin-left: 30px;
    margin-bottom: 20px;
    border-radius: 5%;
}

.animation_content{
    display: inline-flex;
    flex-direction: row;
}

.animation_video_content{
    display: inline-flex;
    flex-direction: row;
}

.animation_video_content video {
    height: 230px;
    margin-left: 30px;
    margin-bottom: 20px;
    border-radius: 5%;
}

.animation {
    padding-left: 30px;
    padding-right: 10px;
    background-color: black;
    padding-bottom: 10px;
}

@media screen and (max-width: 550px){
    .animation h1 {
        margin-bottom: 20px;
        padding-top: 20px;
    
        font-size: 30px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .d_model_photo {
        width: 300px;
        height: 100%;
        margin-bottom: 20px;
        border-radius: 5%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .animation_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .animation_video_content{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .animation_video_content video {
        width: 300px;
        height: 100%;
        margin-bottom: 20px;
        border-radius: 5%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    
    .animation {
        padding-left: auto;
    }

    .link{
        font-family: monospace;
        color: rgb(121, 14, 167);
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        padding-bottom: 30px;
        margin-right: auto;
        padding-top: 0%;
    }
}