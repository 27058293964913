.softwares_bg {
    background-color: black;
    padding-bottom: 50px;
    text-align: center;
}

.softwares_bg li{
    display: inline-flex;
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 70px;
    color: snow;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .softwares_bg li{
        font-size: 15px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }   
}