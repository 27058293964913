.header-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name {
    text-align: center;
    font-size: 100px;
    color: black;
  }

.title {
    text-align: center;
    padding-top: 2%;
    color: snow;
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
}

.moto {
    font-size: 30px;
    text-align: center;
    padding: 2%;
    color: black;
}

@media screen and (max-width: 550px) {
    .name {
        font-size: 40px;
        margin-bottom: 10px;
      }

      .title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .moto {
        font-size: 20px;
        text-align: center;
    }
}