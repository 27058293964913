.page_music{
    padding-top: 30px;
}


.music{
    background-color: black;
}

.music h1{
    padding-top: 50px;
    margin-left: 50px;
    margin-bottom: 30px;

    font-size: 50px;
    font-family: monospace;
    color: snow;
}

.music_container {
  position: relative;
  width: 210px;
  display: inline-flex;
  margin-left: 50px;
  margin-bottom: 40px;
}

.music_container img {
    height: 260px;
    width: 210px;
    border-radius: 2%;
}

.music_container audio{
    position: absolute;
    width: 200px;
    height: 30px;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}


.music_container audio::-webkit-media-controls-seek-back-button,
.music_container audio::-webkit-media-controls-seek-forward-button,
.music_container audio::-webkit-media-controls-fullscreen-button,
.music_container audio::-webkit-media-controls-rewind-button,
.music_container audio::-webkit-media-controls-return-to-realtime-button,
.music_container audio::-webkit-media-controls-toggle-closed-captions-button,
.music_container audio::-webkit-media-controls-mute-button,
.music_container audio::-webkit-media-controls-volume-slider-container,
.music_container audio::-webkit-media-controls-volume-slider{
    display: none;
}

.music_container audio::-webkit-media-controls-timeline{
    width: 50px;
}

.music_container audio::-webkit-media-controls-current-time-display,
.music_container audio::-webkit-media-controls-time-remaining-display{
    font-size: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.music_container audio::-webkit-media-controls-panel{
    background-color: white;
}

.music_container p{
    position: absolute;
    width: 200px;
    background-color: rgb(102, 102, 102);
    color: white;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 550px){
    .music h1{
        padding-top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 20px;
    
        font-size: 30px;
    }

    .music_container {
        position: relative;
        width: 210px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    .music_container audio{
        top: 80%;
    }
}