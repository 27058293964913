.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 0.5rem 2rem;
    padding-top: 30px;
  }
  
  .navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .navbar-links_logo{
    margin-right: 2rem;
  }
  
  .navbar-links_logo img{
    width: 50px;
    height: 50px;
  }
  
  .navbar-links_logo p{
    align-items: center;
    color: rgba( 0, 0, 0, 0.7);
    font-weight: bold;
    font-size: 40px;
    font-family: 'Courier New', Courier, monospace;
    padding: auto;
  }
  
  .navbar-links_container{
    display: flex;
    flex-direction: row;
  }
  
  .navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .navbar-links_container p,
  .navbar-menu_container p,
  .navbar-sign p{
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
  }
  
  .navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
    z-index: 1;
  }
  
  .navbar-menu svg{
    cursor: pointer;
  }
  
  .navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    text-align: end;
    background: rgb(9, 95, 121);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 25px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  }
  
  .navbar-menu_container p{
    margin: 1rem 0;
  }
  
  @media screen and (max-width: 1050px) {
    .navbar-links_container{
      display: none;
    }
  
    .navbar-menu {
      display: flex;
    }
  }
  
  
  @media screen and (max-width: 700px) {
      .navbar {
          padding: 2rem 4rem;
      }
  }
  
  @media screen and (max-width: 550px) {
      .navbar {
          padding: 2rem;
      }
  
      .navbar-menu_container {
          top: 20px;
      }
  
      .navbar-menu_container-links-sign {
          display: block;
      }
  }
  